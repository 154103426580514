import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-loader',
  template: `
    <div class="spinner" [style.width]="width" [style.height]="height"
         [class.dark]="dark">
      <div class="lds-ring" [class]="size"><div></div><div></div><div></div><div></div></div>
    </div>
  `,
  styles:[`
    .spinner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  `],
  imports:[
    CommonModule
  ]
})
export class LoaderComponent implements OnInit {

  @Input()
  public width: string = '2rem';

  @Input()
  public height: string = '2rem';

  @Input()
  public size: 'sm' | 'md' | 'lg' = 'sm'

  @Input()
  public dark: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
